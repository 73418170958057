/**
 * 管理员会员卡相关 API
 */
import { fetch, post } from 'api/base/axios'
import { db_time_to_local_fmt } from 'utils/timeutils'

const URL = {
  addUrl: '/vip_card/admin/create/',
  updateUrl: '/vip_card/admin/update/',
  batchUpdateUrl: '/vip_card/admin/update/batch/',
  listUrl: '/vip_card/admin/list/',
  delUrl: '/vip_card/admin/delete/',

  updateVipUserUrl: '/vip_card/admin/vip_user/update/',
  listVipUserUrl: '/vip_card/admin/vip_user/list/',
};

export function addVIPCard (form) {
  return post(URL.addUrl, form)
}

export function updateVIPCard (form) {
  return post(URL.updateUrl, form)
}

export function batchUpdateVIPCard (uuid_in, action) {
  return post(URL.batchUpdateUrl, {
    'uuid_in': uuid_in,
    'action': action
  })
}

export function listVIPCard (form) {
  return fetch(URL.listUrl, form)
}

export function delVIPCard (form) {
  return post(URL.delUrl, form)
}

export function fmtVIPCardInfo (item) {

  if (!item) {
    return item;
  }

  let type_text = '';
  switch(item.type){
    case 'discount': {
      type_text= '折扣';
    } break;
    case 'free': {
      type_text= '免费';
    } break;
  }
  item.type_text = type_text;

  let cur_scope_text = '';
  let assign_cur_desc_list = [];
  switch(item.cur_scope){
    case 'all': {
      cur_scope_text= '全部课程';
    } break;
    case 'assign': {
      cur_scope_text= '指定课程';

      if (item.assign_curs != undefined){
        item.assign_curs.forEach(item=>{
          let cur_info = item.cur_info;
          if (cur_info){
            assign_cur_desc_list.push({'name': cur_info.name, 'price': cur_info.promote_price})
          }
        });

        if(item.single_show && (item.assign_curs.length === 1 || assign_cur_desc_list.length === 1)){
          cur_scope_text = '仅该课程可用'
        }

      }

    } break;
  }
  item.cur_scope_text = cur_scope_text;
  item.cur_scope_detail= assign_cur_desc_list;

  let expired_method_text = '';
  switch(item.expired_method){
    case 'days': {
      expired_method_text= '指定天数';
    } break;
    case 'cycle': {
      expired_method_text= '指定周期';
    } break;
  }
  item.expired_method_text = expired_method_text;

  let state_text = '';
  switch (item.state) {
    case -0x1 : {
      state_text = '停用';
    } break;
    case -0x2 : {
      state_text = '过期';
    } break;
    case 1 : {
      state_text = '启用';
    } break;
  }
  item.state_text = state_text;

  let available_num_text = '不限';
  if (item.assets_limit){
    item.assets_limit_text = item.assets_limit;

    let avaliable_num = item.assets_limit - item.buy_num;

    if (avaliable_num > 0){
      available_num_text = avaliable_num;
    } else {
      available_num_text = 0;
    }

  } else {
    item.assets_limit_text = '不限';
  }

  item.available_num = available_num_text;

  let scope_text_connect_char = null;

  let vip_card_discount_text = null;
  if (item.type == 'discount') {
    vip_card_discount_text = `${item.discount_val}`;
    scope_text_connect_char = '优惠'
  } else if (item.type == 'free') {
    vip_card_discount_text = '免费';
    scope_text_connect_char = '免费'
  }

  let vip_card_scope_text = null;
  let vip_card_scope_detail_text = null;
  if (item.cur_scope == 'all') {

    vip_card_scope_text = '全场通用VIP';
    vip_card_scope_detail_text = '全部课程可用';

  } else if (item.cur_scope == 'assign') {

    vip_card_scope_text = `课程${scope_text_connect_char}VIP`;
    vip_card_scope_detail_text = '部分课程可用';

  }

  let vip_card_expired_text = null;
  let vip_card_expired_detail_text = null;
  let vip_card_expired_desc_text = null;

  item.expired_cycle_start_local = db_time_to_local_fmt(item.expired_cycle_start);
  item.expired_cycle_end_local =  db_time_to_local_fmt(item.expired_cycle_end);

  if (item.expired_method == 'days') {

    vip_card_expired_text = `${item.expired_days}天`;
    vip_card_expired_detail_text = '';
    vip_card_expired_desc_text = `${item.expired_days}天`;

  } else if (item.expired_method == 'cycle') {

    vip_card_expired_text = '指定使用周期';
    vip_card_expired_detail_text = item.expired_cycle_end_local;
    vip_card_expired_desc_text = `${item.expired_cycle_start_local} ~ ${item.expired_cycle_end_local}`

  }

  item.vip_type = item.type;
  item.discount_text = vip_card_discount_text;
  item.scope_text = vip_card_scope_text;
  item.scope_detail_text = vip_card_scope_detail_text;
  item.expired_text = vip_card_expired_text;
  item.expired_detail_text = vip_card_expired_detail_text;
  item.expired_desc = vip_card_expired_desc_text;

  return item

}


export function listVipUserCard (form) {
  return fetch(URL.listVipUserUrl, form)
}

export function batchUpdateVipUserCard (uuid_in, action) {
  return post(URL.updateVipUserUrl, {
    'uuid_in': uuid_in,
    'action': action
  })
}


