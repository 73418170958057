/**
 * 用户侧 购物车相关 API
 */
import {fetch, post} from 'api/base/axios'

import {fmtVIPCardInfo} from 'api/settlement/vip_card/admin'

const URL = {
  addUrl: '/cart/add/',
  listUrl: '/cart/list/',
  deleteUrl: '/cart/delete/',
  countUrl: '/cart/count/',
};

export const CART_GOODS_TYPE_CUR = 1;
export const CART_GOODS_TYPE_VIP = 2;

const CART_GOODS_TYPE_CUR_TEXT = 'curriculum';
const CART_GOODS_TYPE_VIP_TEXT = 'vip';

export function addCurToCart(cur_uuid) {
  return post(URL.addUrl, {
    'goods_uuid': cur_uuid
  })
}

export function listCartItems() {
  return fetch(URL.listUrl)
}

export function deleteCartItems(uuid_in) {
  return post(URL.deleteUrl, {
    'uuid_in': uuid_in
  })
}

export function countCartItems(filters){
  return fetch(URL.countUrl, filters)
}

export function fmtCartItemInfo(item){

  let goods_info = item.goods_info;

  let goods_type = item.goods_type ? item.goods_type : item.type;

  switch (goods_type) {
    case CART_GOODS_TYPE_CUR:
    case CART_GOODS_TYPE_CUR_TEXT:{
      let cur_type = '';
      if (goods_info.live_num > 0 && goods_info.face_num == 0 && goods_info.video_num == 0 && goods_info.auth_count == 0) {
        cur_type = '直播课';
      } else if (goods_info.face_num > 0 && goods_info.live_num == 0 && goods_info.video_num == 0 && goods_info.auth_count == 0) {
        cur_type = '面授课'
      } else if (goods_info.video_num > 0 && goods_info.live_num == 0 && goods_info.face_num == 0 && goods_info.auth_count == 0) {
        cur_type = '视频课'
      } else if (goods_info.auth_count > 0 && goods_info.live_num == 0 && goods_info.face_num == 0 && goods_info.video_num == 0) {
        cur_type = '认证码'
      } else if ((goods_info.auth_count + goods_info.live_num + goods_info.face_num + goods_info.video_num) > 0) {
        cur_type = '组合课'
      }

      item.is_promote = true;
      item.goods_type_text = 'curriculum';
      item.cur_uuid = goods_info.cur_uuid;
      item.cur_name = goods_info.name;
      item.cur_type = cur_type;
      item.cur_label = goods_info.image_label;
      item.cur_cover = goods_info.cover_img;
      item.price = goods_info.promote_price;
      item.chapter_desc = `${goods_info.chapter_num}章${goods_info.section_num}节`;
    } break;

    case CART_GOODS_TYPE_VIP:
    case CART_GOODS_TYPE_VIP_TEXT: {
      item.goods_type_text = 'vip';
      item.goods_info = fmtVIPCardInfo(goods_info);
      item.price = goods_info.promote_price;
    }
      break;
  }

  return item

}





