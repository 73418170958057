/**
 * 管理员订单相关 API
 */
import { fetch, post } from 'api/base/axios'
import { fmtVIPCardInfo } from 'api/settlement/vip_card/admin'
import { delta_days_text, db_time_to_local_fmt } from 'utils/timeutils'

const URL = {
  listUrl: '/order/admin/list/',
  detailUrl: '/order/admin/detail/',
  reduceUrl: '/order/admin/reduce/',
  deleteUrl: '/order/admin/delete/',
  curPurchasedPermsListUrl: '/settlement/cur/admin/cur_purchased_perms_list/',
  curPurchasedPermsStatUrl: '/settlement/cur/admin/cur_purchased_perms_stats/',
  exportTaskCreateUrl: '/order/admin/export/',
  exportTaskQueryUrl: '/order/admin/export/query/',
  exportTaskDownloadUrl: '/order/admin/export/download/',

  listTransactionUrl: '/order/admin/transaction_list/',

  exportTransactionTaskCreateUrl: '/order/admin/transaction_export/',
  exportTransactionTaskQueryUrl: '/order/admin/transaction_export/query/',
  exportTransactionTaskDownloadUrl: '/order/admin/transaction_export/download/',
};

export const ORDER_STATE_UNPAY = 1;
const ORDER_GOODS_TYPE_CUR = 1;
const ORDER_GOODS_TYPE_VIP = 2;

export function listOrder (form) {
  return post(URL.listUrl, form)
}

export function listTransaction (form) {
  return post(URL.listTransactionUrl, form)
}

export function createExportOrderTask(form){
  return post(URL.exportTaskCreateUrl, form)
}

export function queryExportOrderTask(){
  return fetch(URL.exportTaskQueryUrl)
}


export function createExportTransactionTask(form){
  return post(URL.exportTransactionTaskCreateUrl, form)
}

export function queryExportTransactionTask(){
  return fetch(URL.exportTransactionTaskQueryUrl)
}

export function detailOrder(order_uuid){
  return fetch(URL.detailUrl, {
    'order_uuid': order_uuid
  })
}

export function reduceOrder (form) {
  return post(URL.reduceUrl, form)
}

export function deleteOrder (uuid_in) {
  return post(URL.deleteUrl, {
    'uuid_in': uuid_in,
  })
}

export function getOrderStateText(order_state){

  let state_text = '';
  switch (parseInt(order_state)) {
    case 1 : {
      state_text = '待支付';
    } break;
    case 2 : {
      state_text = '已取消';
    } break;
    case 3 : {
      state_text = '待收款';
    } break;
    case 4 : {
      state_text = '已支付';
    } break;
    case 5 : {
      state_text = '已关闭';
    } break;
  }
  return state_text

}

export function getCurPurchasedPermsList(form){
    return post(URL.curPurchasedPermsListUrl, form)
}

export function getCurPurchasedPermsStats(form){
    return post(URL.curPurchasedPermsStatUrl, form)
}

export function fmtOrderInfo (item) {

  if(!item){
    return item;
  }

  let type_text = '';
  switch(item.type){
    case 1: {
      type_text= '课程订单';
    } break;
    case 2: {
      type_text= '会员卡订单';
    } break;
    case 3: {
      type_text= '混合订单';
    } break;
  }
  item.type_text = type_text;

  item.state_text = getOrderStateText(item.state);

  let payment_type_text = null;

  if (item.payment){
    switch (item.payment.type) {
      case 1 : {
        payment_type_text = '支付宝';
      } break;
      case 2 : {
        payment_type_text = '微信支付';
      } break;
      case 3 : {
        payment_type_text = '线下支付';
      } break;
      case 4 : {
        payment_type_text = '无需支付';
      } break;
    }
  }

  item.created_at_local =  db_time_to_local_fmt(item.created_at);
  item.payed_at_local =  item.payed_at != null ? db_time_to_local_fmt(item.payed_at) : '-';

  item.payment_type_text = payment_type_text;

  item.goods_num = item.order_goods ? item.order_goods.length : 0;

  if (item.user_info){

    let job_zh = '';
    switch(item.user_info.job){
      case 'office': {
        job_zh = '职员';
      } break;
      case 'student': {
        job_zh = '学生';
      } break;
      case 'teacher': {
        job_zh = '教师';
      } break;
    }

    item.user_info.job_zh = job_zh;

  }

  return item

}

export function fmtOrderGoodsInfo(item){


  let goods_info = item.goods_info;

  if (!item || !goods_info) {
    return item;
  }

  if (item.type == ORDER_GOODS_TYPE_CUR){

    let cur_type = '';
    if (goods_info.live_num > 0 && goods_info.face_num == 0 && goods_info.video_num == 0 && goods_info.auth_count == 0 && goods_info.experiment_num == 0) {
      cur_type = '直播课';
    } else if (goods_info.face_num > 0 && goods_info.live_num == 0 && goods_info.video_num == 0 && goods_info.auth_count == 0 && goods_info.experiment_num == 0){
      cur_type = '面授课'
    } else if (goods_info.video_num > 0 && goods_info.live_num == 0 && goods_info.face_num == 0 && goods_info.auth_count == 0 && goods_info.experiment_num == 0){
      cur_type = '视频课'
    } else if (goods_info.auth_count > 0 && goods_info.live_num == 0 && goods_info.face_num == 0 && goods_info.video_num == 0 && goods_info.experiment_num == 0){
      cur_type = '认证码'
    } else if (goods_info.experiment_num > 0 && goods_info.live_num == 0 && goods_info.face_num == 0 && goods_info.video_num == 0 && goods_info.auth_count ==0){
      cur_type = '实验课'
    } else if ((goods_info.auth_count + goods_info.live_num + goods_info.face_num + goods_info.video_num + goods_info.experiment_num) > 0) {
      cur_type = '组合课'
    }
    item.cur_type = cur_type;

    item.cur_cover = goods_info.cover_img;
    item.cur_label = goods_info.image_label;

    let chapter_sections = goods_info.live_num + goods_info.face_num + goods_info.video_num  + goods_info.auth_count + goods_info.experiment_num;
    item.chapter_desc = `${goods_info.chapter_num}章${chapter_sections}节`;

    let promotes_desc = '';
    let expired_days = null;
    if (item.vips && item.vips.length > 0){
        promotes_desc = item.vips[0].vip_card_desc;

        if(item.vips[0].vip_card && item.vips[0].vip_card.type === 'free'){
          expired_days = Math.ceil(delta_days_text(item.vips[0].rights_end));
        }

    }

    if (item.coupons && item.coupons.length > 0){
        promotes_desc = item.coupons[0].coupons_desc;
    }

    item.promote_desc = promotes_desc;
    item.goods_type = 'curriculum';
    item.expired_days = expired_days;

  } else if (item.type == ORDER_GOODS_TYPE_VIP){


    let filled_info = fmtVIPCardInfo(goods_info);

    item.vip_type = filled_info.vip_type;
    item.discount_text = filled_info.discount_text;
    item.scope_text = filled_info.scope_text;
    item.scope_detail_text = filled_info.scope_detail_text;
    item.expired_text = filled_info.expired_text;
    item.expired_detail_text = filled_info.expired_detail_text;
    item.expired_method = filled_info.expired_method;

    item.goods_type = 'vip'
  }

  return item;

}

