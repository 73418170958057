/**
 * 用户侧订单相关 API
 */
import { fetch, post } from 'api/base/axios'
import {fmtOrderGoodsInfo, fmtOrderInfo} from 'api/settlement/order/admin'
import { db_time_to_local_fmt, delta_time_secdons } from 'utils/timeutils'

const URL = {
  prepareUrl: '/order/prepare/',
  confirmUrl: '/order/confirm/',
  confirmListPromotesUrl: '/order/confirm/load_promotes',
  submitUrl: '/order/submit/',
  cancelUrl: '/order/cancel/',
  closeUrl: '/order/close/',
  listPromotesUrl: '/order/promotes/list/',
  discountAmountUrl: '/order/confirm/discount_amount/',
  detailUrl: '/order/detail/',
  listUrl: '/order/list/',
  deleteUrl: '/order/delete/',
};

export function prepareOrder(order_goods) {
  return post(URL.prepareUrl, {
    'order_goods': order_goods
  })
}

export function confirmOrder(confirm_key) {
  return fetch(URL.confirmUrl, {
    'confirm_key': confirm_key
  })
}

export function confirmListPromotes(confirm_key) {
  return fetch(URL.confirmListPromotesUrl, {
    'confirm_key': confirm_key
  })
}

export function listPromotes(form) {
  return post(URL.listPromotesUrl, form)
}

export function discountAmount(form){
  return post(URL.discountAmountUrl, form)
}

export function submitOrder(form){
 return post(URL.submitUrl, form)
}

export function detailOrder(order_uuid) {
  return fetch(URL.detailUrl, {
    'order_uuid': order_uuid
  })
}

export function cancelOrder(form){
 return post(URL.cancelUrl, form)
}

export function closeOrder(form){
 return post(URL.closeUrl, form)
}

export function deleteOrder(form) {
  return post(URL.deleteUrl, form)
}

export function listOrder(form) {
  return fetch(URL.listUrl, form)
}

export function fmtUserOrderInfo(order_info) {

  order_info = fmtOrderInfo(order_info);

  let filled_order_goods_list = [];

  order_info.order_goods.forEach((item) => {

    item = fmtOrderGoodsInfo(item);
    item.is_recommend = true;
    filled_order_goods_list.push(item);

  });

  order_info.created_at_local = db_time_to_local_fmt(order_info.created_at);
  order_info.order_goods = filled_order_goods_list;
  order_info.order_goods_count = filled_order_goods_list.length;

  if(order_info.state === 1){
    order_info.cancel_seconds = order_info.overdue_seconds;
  }

  order_info.checked = false;

  return order_info;

}
