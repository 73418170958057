<template>
  <div class="container_wrap">
    <h1>购物车
      <el-button type="text"
                 @click="clearInvalidGoods"
                 v-if="invalidGoodsCount > 0">移除失效商品
      </el-button>
    </h1>

    <el-table :data="cartItems"
              :empty-text="cartItemsEmptyMsg"
              v-loading="cartItemsLoading"
              class="order_table_common"
              @selection-change="selectRowChanged">

      <el-table-column type="selection" width="55" :selectable="cartItemCheckBoxEnableNotify"></el-table-column>
      <el-table-column prop="info" label="商品信息">
        <template #default="scope">
          <div :class="{'course_item_common': true, 'pointer': true, 'disabled': scope.row.is_valid == false}">
            <div class="cover" @click="goCurDetail(scope.row.cur_uuid)">
              <img :src="scope.row.cur_cover" />
              <span class="hot" v-if="scope.row.cur_label && scope.row.cur_label != 'null'">{{scope.row.cur_label}}</span>
              <span class="tag" v-if="scope.row.cur_type">{{ scope.row.cur_type }}</span>
              <div class="layer" v-if="scope.row.is_valid == false">已失效</div>
            </div>
            <div class="content">
              <h4 @click="goCurDetail(scope.row.cur_uuid)">{{ scope.row.cur_name }}</h4>
              <div class="info">
                章节：{{ scope.row.chapter_desc }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" width="150">
        <template #default="scope">
          <span :style="scope.row.is_valid == false ? 'color:#999;' : ''">¥ {{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="优惠" width="180">
        <template #default="scope">
          <span v-if="scope.row.promotes_count > 0">已有可用优惠</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template #default="scope">
          <el-button class="btn_danger_common" @click="deleteCartGoods([scope.row.uuid])"
                     type="danger"> 移除
          </el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="settle_wrap">
      已选中 {{ selectedRows.length }} 个商品，金额：<span>¥{{selectedAmount}}</span>
      <el-button type="danger" round @click="buySelectedGoods" :disabled="selectedRows.length == 0">立即购买</el-button>
    </div>

    <!-- 用户信息 -->
    <el-dialog
        title="填写个人信息"
        v-model="userModal.user_message"
        width="500px"
        :before-close="cancel_message"
        >
      <el-alert title="请如实填写您的个人信息，以保证您的正常学习！" center type="warning" :closable="false" style="margin-bottom:20px;"></el-alert>
      <el-form :model="userModal.userList" :rules="rules" ref="infoForm" label-width="90px" >
        <el-form-item label="姓名" prop="realname">
            <el-input v-model.trim="userModal.userList.realname"></el-input>
        </el-form-item>
        <el-form-item label="职业" prop="job">
            <el-radio-group v-model="userModal.userList.job">
              <el-radio label="office">职员</el-radio>
              <el-radio label="student">学生</el-radio>
              <el-radio label="teacher">教师</el-radio>
            </el-radio-group>
          </el-form-item>
        <el-form-item label="单位/学校" prop="work_place">
            <el-input v-model.trim="userModal.userList.work_place"></el-input>
        </el-form-item>
        <el-form-item label="岗位/专业" prop="job_name">
            <el-input v-model.trim="userModal.userList.job_name"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('infoForm')">确  定</el-button>
            <el-button @click="cancel_message">取  消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  import {listCartItems, fmtCartItemInfo,  deleteCartItems, CART_GOODS_TYPE_CUR, CART_GOODS_TYPE_VIP} from 'api/settlement/cart/user'
  import {prepareOrder} from 'api/settlement/order/user'
  import {checkMessage} from 'api/promoter/selectPromo'
  import { updateUserInfo } from 'api/user/user/user'
  import { GetUserState } from 'api/user/auth/login'
  export default {
    components: {
    },
    data() {
      return {
        cartItems: [],
        cartItemsLoading: true,
        cartItemsEmptyMsg: ' ',
        selectedRows: [],
        selectedAmount: 0,
        invalidGoodsCount: 0,
        userModal:{
          user_id:'',
          user_message:false,
          userList:{
            realname:'',
            job:'office',
            work_place:'',
            job_name:''
          }
        },
        rules: {
          job_name: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          work_place: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          realname: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ]
        }
      }
    },
    mounted() {
      this.loadCartItems()
      this.getuserId()
    },
    methods: {
      getuserId(){
        GetUserState().then((res) => {
          this.userModal.user_id = res.id
        })
      },
      loadCartItems(){

        this.cartItemsLoading = true;

        listCartItems().then(result => {

          if (result.succeed) {

            this.cartItems = [];
            let validCartItems = [];
            let invalidCartItems = [];

            result.data.forEach(item => {
              let filled_item = fmtCartItemInfo(item);

              if (item.is_valid){
                  validCartItems.push(filled_item)
              } else {
                  invalidCartItems.push(filled_item)
              }

            });

            validCartItems.forEach(item=>{ this.cartItems.push(item) });
            invalidCartItems.forEach(item=>{ this.cartItems.push(item) });

            this.invalidGoodsCount = invalidCartItems.length;

            if (this.cartItems.length === 0){
                this.cartItemsEmptyMsg = '暂无数据';
            }

            this.$emit('update:cartItemCount', result.data.length);

          } else {

            switch (result.error_code) {
              case -9999 : {
                this.$emit('update:loginVisible', true);
              }
                break;
              default: {
                this.$alert(`${result.error_msg}`, '提示', {
                  confirmButtonText: '确定'
                });
              }
                break;
            }

          }

          this.cartItemsLoading = false;

        }).catch(err => {
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      selectRowChanged(val) {
        this.selectedRows = val;

        let amount = 0;

        this.selectedRows.forEach(item=>{
            amount += item.price;
        });

        this.selectedAmount = parseFloat(amount.toFixed(2));

      },
      deleteCartGoods(goods_uuid_arr, remove_invalid=false){

        const that = this;

        let confirm_msg = remove_invalid ? '您确定要移除失效的商品吗？' : '您确定要删除该购物车商品吗?';

        this.$confirm(confirm_msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() => {

          deleteCartItems(goods_uuid_arr).then(result=>{

            if (result.succeed) {

                that.loadCartItems();

            } else {
              that.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定'
              });
            }

          }).catch(err => {
            that.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });

        }).catch(() => {});

      },
      clearInvalidGoods(){

        let invalid_cart_uuid_list = [];
        this.cartItems.forEach(item => {

          if (item.is_valid == false) {
            invalid_cart_uuid_list.push(item.uuid)
          }

        });

        if (invalid_cart_uuid_list.length > 0) {
            this.deleteCartGoods(invalid_cart_uuid_list, true);
        } else {
          this.$alert('购物车中不存在失效的商品', '提示', {
            confirmButtonText: '确定'
          });
        }

      },
      cartItemCheckBoxEnableNotify(row, index){
        return row.is_valid;
      },
      buySelectedGoods(){
        checkMessage().then((res) => {
          if(res.data == 0) {
            if (this.selectedRows.length > 0) {

              let prepare_data = [];
              this.selectedRows.forEach(item => {
                prepare_data.push({
                  type: item.goods_type_text,
                  res_uuid: item.goods_uuid
                })
              });

              console.log(prepare_data);

              const that = this;
              prepareOrder(prepare_data).then(result => {

                if (result.succeed) {
                  that.goPath(`/order/confirm/${result.confirm_key}`);
                } else {
                  that.$alert(`${result.error_msg}`, '提示', {
                    confirmButtonText: '确定'
                  });
                }

              }).catch(err => {
                that.$alert(`${err}`, '提示', {
                  confirmButtonText: '确定'
                });
              });


            } else {
              this.$alert('您尚未选择需要购买的商品', '提示', {
                confirmButtonText: '确定'
              });
            }
          }else{
            this.userModal.userList.realname = res.user.realname
            this.userModal.userList.job_name = res.user.job_name
            if (res.user.job != null){
              this.userModal.userList.job = res.user.job
            }else{
              this.userModal.userList.job = 'office'
            }
            this.userModal.userList.work_place = res.user.work_place
            this.userModal.user_message = true
          }
        })

      },
      goPath(path) {
        this.$router.push(path);
      },
      goCurDetail(cur_uuid){
        const cur_detail_page = this.$router.resolve({name: 'CourseDetail', params: {uuid: cur_uuid}});
        window.open(cur_detail_page.href, '_blank');
      },


      updateUserInfoMethod(data) {
        updateUserInfo(this.userModal.user_id, data)
          .then((res) => {
            this.loading = false;
            if (res.result === 0) {
              this.$router.go(0);
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          }).catch((err) => {
          this.loading = false;
          this.$alert('服务异常，请稍后再试', '提示', {
                confirmButtonText: '确定',
          });
        })
      },
      cancel_message() {
        this.$refs['infoForm'].resetFields()
        this.userModal.user_message = false
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.updateUserInfoMethod({"job": this.userModal.userList.job, "job_name": this.userModal.userList.job_name, "work_place": this.userModal.userList.work_place, "realname": this.userModal.userList.realname});
          } else {
            return false;
          }
        });
      },

    }
  }
</script>

<style lang="scss" scoped>
  h1 {
    font-size:30px;
    color:#333;
    margin:0;
    font-weight: normal;
    padding:20px 0 30px 0;
    .el-button {
      float:right;
      color:#999;
      &:hover {
        color:#00adef;
      }
    }
  }
  .settle_wrap {
    padding-top:50px;
    padding-bottom:50px;
    text-align:right;
    > span {
      margin-left:15px;
      font-size:42px;
      color:#f51c21;
      font-family: 'font-540';
      vertical-align: middle;
    }
    .el-button {
      font-size:16px;
      padding:15px 50px;
      border-radius:100px;
      margin-left:60px;
    }
  }
  .course_item_common {
    &.disabled {
      .content {
        > h4, .info {
          color:#999;
        }
      }
    }
  }
</style>
