import { fetch, post, del } from 'api/base/axios'

const URL = {
  addPathUrl: '/promoter/add_path_promo/',
  checkPromoCodeUrl: '/promoter/check_promo_code/',
  checkMessageUrl: '/promoter/check_message/',
}

export function addPath (data) {
  return fetch(URL.addPathUrl, data)
}

export function checkPromoCode (data) {
  return fetch(URL.checkPromoCodeUrl, data)
}

export function checkMessage (data) {
  return fetch(URL.checkMessageUrl, data)
}
