/**
 * 数据库时间(ISO8601，)转换为本地时间
 */

Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, //month

    "d+": this.getDate(), //day

    "h+": this.getHours(), //hour

    "m+": this.getMinutes(), //minute

    "s+": this.getSeconds(), //second

    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter

    "S": this.getMilliseconds() //millisecond

  }

  if (/(y+)/.test(format)) format = format.replace(RegExp.$1,

    (this.getFullYear() + "").substr(4 - RegExp.$1.length));

  for (var k in o)if (new RegExp("(" + k + ")").test(format))

    format = format.replace(RegExp.$1,

      RegExp.$1.length == 1 ? o[k] :

        ("00" + o[k]).substr(("" + o[k]).length));

  return format;

};
Date.prototype.setISO8601 = function (string) {
        var regexp = "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
            "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?" +
            "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
        if(string)
        {
            var d = string.match(new RegExp(regexp));
            var offset = 0;
            var date = new Date(d[1], 0, 1);

            if (d[3]) {
                date.setMonth(d[3] - 1);
            }
            if (d[5]) {
                date.setDate(d[5]);
            }
            if (d[7]) {
                date.setHours(d[7]);
            }
            if (d[8]) {
                date.setMinutes(d[8]);
            }
            if (d[10]) {
                date.setSeconds(d[10]);
            }
            if (d[12]) {
                date.setMilliseconds(Number("0." + d[12]) * 1000);
            }
            if (d[14]) {
                offset = (Number(d[16]) * 60) + Number(d[17]);
                offset *= ((d[15] == '-') ? 1 : -1);
            }
            offset -= date.getTimezoneOffset();
            let time_obj = (Number(date) + (offset * 60 * 1000));
            this.setTime(Number(time_obj));
        }
        else
        {
            return;
        }
    };



const DATETIME_FMT = 'yyyy-MM-dd hh:mm:ss';

export function db_time_to_local_fmt(db_str, fmt=DATETIME_FMT) {

  let tmp = new Date();
  tmp.setISO8601(db_str);
  return tmp.format(fmt);

}

function getOffsetDays(startDate, endDate) {
	//得到时间戳相减 得到以毫秒为单位的差
	var mmSec = (endDate.getTime() - startDate.getTime());
	//单位转换为天并返回
	return (mmSec / 3600000 / 24);
}

export function delta_days_text(delta_time){

  let tmp = new Date();
  tmp.setISO8601(delta_time);

  return getOffsetDays(new Date(), tmp);
}

export function delta_time_secdons(db_time_str, offset_day = 0) {

  let tmp = new Date();
  tmp.setISO8601(db_time_str);

  var current_time = new Date().getTime();//当前的毫秒数
  var oneDay = 86400000; //一天的毫秒数

  return ((tmp.getTime() + oneDay * offset_day) - current_time) / 1000

}

export function js_time_to_str(date_obj, fmt=DATETIME_FMT){
  let tmp = new Date(date_obj);
  return tmp.format(fmt);
}
