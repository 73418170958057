/**
 * 后台角色API
 */
import { fetch, post, del, postForm } from 'api/base/axios';

const URL = {
  getUserList: '/user/manage/list/',
  lockUserList: '/user/manage/lock/',
  unLockUserList: '/user/manage/unlock/',
  deleteUserList: '/user/manage/delete/',
  getUserInfoUrl: '/user/manage/info/',
  getUserPayedUrl: '/user/manage/payed/',

  updateUserInfoUrl: '/user/manage/update/',

  getSystemUserList: '/user/manage/system/list/',
  addSystemUserUrl: '/user/manage/system/add/',
  getSystemRoleList: '/user/manage/system/role/list/',

  checkUserInfoUrl: '/user/manage/check/user/',

  getUserStatisticUrl: '/user/manage/statistic/',
  getUserDayStatisticUrl: '/user/manage/day/statistic/',

  checkRoutePermissionUrl: '/user/manage/check/permission/',

  exportUserUrl: '/user/manage/export/',
  queryExportUserUrl: '/user/manage/export/query/',


  downloadUserUrl: '/user/manage/download/',
};

export function GetUserList (data) {
  return fetch(URL.getUserList, data);
}

export function getUserPayed (data) {
  return fetch(URL.getUserPayedUrl, data);
}

export function deleteUsers (data) {
  return post(URL.deleteUserList, data);
}

export function unLockUsers (data) {
  return post(URL.unLockUserList, data);
}

export function lockUsers (data) {
  return post(URL.lockUserList, data);
}

export function GetSystemUserList (data) {
  return fetch(URL.getSystemUserList, data);
}

export function addSystemUser (form) {
  return post(URL.addSystemUserUrl, form);
}

export function GetSystemRoleList() {
  return fetch(URL.getSystemRoleList);
}

export function GetUserInfo(user_id) {
  return fetch(URL.getUserInfoUrl + user_id + "/");
}

export function updateUserInfo(user_id, data) {
  return post(URL.updateUserInfoUrl + user_id + "/", data);
}

export function getUserStatistic() {
  return post(URL.getUserStatisticUrl);
}

export function getUserDayStatistic(data) {
  return post(URL.getUserDayStatisticUrl, data);
}

export const checkRoutePermission = async (url) => {
  return await post(URL.checkRoutePermissionUrl, {"url": url});
}

export function CheckUserInfoExist (data, callback, name) {
  fetch(URL.checkUserInfoUrl, data).then((res) => {
    if (!res.result) {
      callback()
    } else {
      return callback(new Error('该'+ name +'已被注册'))
    }
  }).catch(err => {
    return callback(new Error('服务异常，请稍后再试'))
  });
}

export function mobileCheckUserInfoExist (data, name) {
  return new Promise(resolve => {
    fetch(URL.checkUserInfoUrl, data).then((res) => {
      if (!res.result) {
        resolve(true)
      } else {
        resolve('该'+ name +'已被注册')
      }
    }).catch(err => {
      resolve('服务异常，请稍后再试')
    });
  })
}

export function exportUser(data){
  return post(URL.exportUserUrl, data)
}

export function queryExportUser(){
  return fetch(URL.queryExportUserUrl)
}

export function download_user(data){
  return fetch(URL.downloadUserUrl,data)
}
